<template>
  <div id="bottom-bar">
    <div id="header" class="bg-white shadow-sm" style="z-index: 9;">
      <div class="container-fluid py-2 px-3 px-md-4">
        <div class="row justify-content-between align-items-center">
          <div class="col-8 col-md-6">
            <a href="javascript:" class="text-dark text-decoration-none" title="Acessar Home" @click="alterarRegional()">
              <div style="padding: 6px 0">
                <h2 class="title">Calendário CCB</h2>
                <div class="text-muted small">
                  {{regional.nome_regional}} - {{regional.sigla_estado}} <i class="fa fa-angle-down text-secondary" v-if="isCalendario"></i>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-6 d-flex justify-content-end align-items-center">
            <router-link to="/listas" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Listas">
              <span class="icone-text">Listas</span>
            </router-link>
            <router-link to="/presencas" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Notificações">
              <span class="icone-text">Presenças</span>
              <span class="n-notificacao" v-show="qtdNotificacao > 0">{{qtdNotificacao}}</span>
            </router-link>
            <router-link to="/regional" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Sobre nós">
              <span class="icone-text">Sobre nós</span>
            </router-link>
            <router-link to="/perfil" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Meu Perfil" v-if="token">
              <span class="icone-text">Perfil</span>
            </router-link>
            <router-link to="/regional" active-class="active" class="btn d-md-none" role="listitem" aria-label="Quem somos">
              <img src="../assets/icones/info.svg" alt="Quem somos" style="width: 22px">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center fixed-bottom d-md-none" style="z-index: 9">
      <div class="bottom-bar shadow-top position-relative" style="border-radius: 24px 24px 0 0" role="navigation">
        <div class="container">
          <div class="row align-items-center" role="list">
            <router-link to="/calendario" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Home">
              <div aria-hidden="true">
                <img src="../assets/icones/calendar-lines.svg" alt="Calendário">
                <div class="text-bottom">Calendário</div>
              </div>
            </router-link>
            <router-link to="/listas" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Home">
              <div aria-hidden="true">
                <img src="../assets/icones/rectangle-list.svg" alt="Listas">
                <div class="text-bottom">Listas</div>
              </div>
            </router-link>
            <router-link to="/presencas" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Notificações">
              <div aria-hidden="true">
                <img src="../assets/icones/bell.svg" alt="Home">
                <span class="n-notificacao" v-show="qtdNotificacao > 0">{{qtdNotificacao}}</span>
                <div class="text-bottom">Presenças</div>
              </div>
            </router-link>
            <router-link to="/perfil" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Perfil">
              <div aria-hidden="true">
                <img src="../assets/icones/user.svg" alt="User">
                <div class="text-bottom">Perfil</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
export default {
  name: 'BottonBar',
  components: {Icon},
  data() {
    return {
      token: localStorage.getItem('token'),
      qtdInfo: 1,
      isCalendario: false
    }
  },

  methods: {
    alterarRegional() {
      if (this.isCalendario) {
        this.$swal({
          text: "Deseja acessar outra regional?",
          buttons: ["Cancelar", "Sim"],
          dangerMode: true
        }).then((res => {
          if (res) {
            localStorage.removeItem('setor');
            this.$store.state.id_regional = null;
            this.$store.state.user.id_regional = null;
            localStorage.setItem('userData', JSON.stringify(this.$store.state.user));
            this.$router.push('/');
          }
        }));

      } else if (this.$route.name !== 'Calendario') {
        this.$router.push('/calendario');
      }
    }
  },

  mounted() {
    this.isCalendario = this.$route.name === 'Calendario';
  },

  computed: {
    regional() {
      return this.$store.state.dados.regional;
    },

    qtdNotificacao() {
      const qtd = this.$store.state.notificacoes.length;
      return qtd > 99 ? 99 : qtd;
    }
  },
}
</script>
<style scoped>
.title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1rem;
}
.bottom-bar {
  background-color: var(--primary);
}

.icone-bar {
  padding: 14px 0;
  color: #ffffff;
}

.icone-bar.active:first-child {
  border-radius: 24px 0 0;
}

.icone-bar.active:last-child {
  border-radius: 0 24px 0 0;
}

.icone-desktop img, .icone-bar img {
  width: 18px;
}

.text-bottom {
  font-size: 11px;
  margin-top: 6px;
}

.icone-desktop {
  padding: 12px 16px;
  color: var(--dark);
  margin-left: 12px;
  text-align: center;
  display: none;
  align-items: baseline;
  position: relative;
}

.icone-desktop .icone-text {
  white-space: nowrap;
  margin-left: 6px;
}

.icone-desktop.active {
  color: var(--primary);
  font-weight: bold;
}

.icone-desktop.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
}

.n-notificacao {
  position: absolute;
  left: 54%;
  top: 8px;
  transform: translateX(-50%);
  background-color: var(--secondary)!important;;
  color: #fff!important;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  line-height: 11px;
  font-weight: bold;
}

.n-desktop {
  left: 26px;
  top: -6px;
  transform: none;
  margin-left: 0;
}

.icone-bar.active {
  background-color: rgba(0, 0, 0, 0.2);
}

.icone-bar.active::before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  top: 10px;
  right: 33%;
  background-color: #ffffff;
}

.ios_platform .icone-bar {
  padding-bottom: 34px;
}

@media (min-width: 768px) {
  .icone-desktop {
    display: flex;
  }

  .n-notificacao {
    top: 2px;
    left: auto;
    right: 0;
  }
}
</style>

<template>
  <div class="container text-center">
    <loading v-if="load" fullscrean="true" text="Redirecionando para o App"/>
    <div class="mx-auto" style="padding-top: 20vh; max-width: 500px" v-else>
      <div class="text-center">
        <img src="../assets/logo.png" alt="Congregação Cristã no Brasil" class="img-fluid" style="max-width: 128px;">
        <h2 class="font-weight-bold text-primary mt-3 mb-0">CRS</h2>
        <div>Calendário de Reuniões e Serviços</div>
      </div>
      <div style="margin-top: 42px">
        <a :href="android" v-if="android" target="_blank" class="btn btn-links">
          <i class="fa fa-play"></i>
          Baixar App Android
        </a>
        <a :href="ios" v-if="ios" target="_blank" class="btn btn-links">
          <i class="fa fa-apple"></i>
          Baixar App iOS
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
  name: 'Share',
  components: {Loading},
  data() {
    return {
      load: true,
      android: 'https://play.google.com/store/apps/details?id=br.com.calendarioccb.twa',
      ios: 'https://apps.apple.com/br/app/calendário-ccb/id6475646146'
    }
  },

  mounted() {
    const ua = navigator.userAgent

    if (/android/i.test(ua)) {
      window.location.href = this.android;

    } else if (/iPad|iPhone|iPod/.test(ua)) {
      window.location.href = this.ios;

    } else {
      this.load = false;
    }
  },

  computed: {
    regional() {
      return this.$store.state.dados.regional;
    }
  }
}
</script>
<style scoped>
</style>
<template>
  <div class="card lista">
    <a href="javascript:" class="card-body text-decoration-none text-primary" :class="{'d-flex align-items-center': icone}" @click="go(url)">
      <div>
        <h6 class="mb-0 font-weight-bold text-uppercase">{{ nome }}</h6>
        <slot/>
      </div>
      <div class="ml-auto text-center" v-if="icone">
        <i class="fa fa-angle-right"></i>
      </div>
    </a>
  </div>
</template>

<script>
import Icon from "./Icon";
export default {
  name: 'Lista',
  components: {Icon},
  props: ['nome', 'descricao', 'icone', 'url', 'id', 'external'],

  methods: {
    go(url) {
      if (url) {
        if (this.external) {
          window.open(url, '_blank')

        } else {
          this.$router.push(url);
        }

      } else {
        this.$emit('go', this.id)
      }
    }
  }
}
</script>

<style>
  .limite {
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
</style>

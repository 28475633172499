<template>
  <div @click="to()" :class="link ? 'pointer' : ''">
    <div class="card-info shadow-sm px-2 align-items-center bg-white">
      <div class="text-center" v-if="icon" style="padding: 0 14px 0 7px">
        <icon :name="icon" size="28" class="text-primary"/>
      </div>
      <div class="text-muted">
        <div class="font-weight-bold text-primary limite" v-html="title"></div>
        <slot></slot>
      </div>
      <div class="align-self-center text-primary text-center" style="width: 40px; margin-left: auto" v-if="link || iconPos">
        <i class="fa" :class="iconPos ? iconPos : 'fa-info-circle'"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from "./Icon";
  export default {
    components: {Icon},
    props: ['title', 'link', 'action', 'icon', 'icon-pos'],
    name: "CardInfo",
    methods: {
      to() {
        if (this.link) {
          this.$router.push(this.link)
        } else {
          this.$emit('action')
        }
      }
    }
  }
</script>

<style scoped>
  .card-info {
    padding: 14px 6px;
    color: #343a40;
    border-radius: 8px;
    display: flex;
    border: 1px solid #e9e9e9;
  }
  .small {
    line-height: 15px;
  }
  .limite {
    max-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
</style>

<template>
  <div>
    <top-bar title="Quem Somos" url="/"/>
    <div class="container" style="max-width: 600px">
      <div class="text-center">
        <h2 class="font-weight-bold text-primary mt-3 mb-0">CRS</h2>
        <div>Calendário de Reuniões e Serviços</div>
      </div>
      <hr>
      <div>
        <p>
          Bem-vindo ao CRS - Calendário CCB, um sistema completo para organizar o Calendário de Reuniões e as Listas de Batismos da Congregação Cristã no Brasil nas Regionais Ministeriais (RRM).
        </p>
        <p>
          Se houver interesse em fazer parte deste projeto (gratuitamente),
          o responsável pela RRM de sua região deverá fazer o cadastro
          <a href="https://forms.gle/TkA2GGioyEGXJW44A" target="_blank" class="text-secondary">neste link</a>.
        </p>
      </div>
      <div style="margin: 32px 0 120px">
        <a href="https://play.google.com/store/apps/details?id=br.com.calendarioccb.twa" target="_blank" class="btn btn-links">
          <i class="fa fa-play"></i>
          Baixar App Android
        </a>
        <a href="https://apps.apple.com/br/app/calendário-ccb/id6475646146" target="_blank" class="btn btn-links">
          <i class="fa fa-apple"></i>
          Baixar App iOS
        </a>
      </div>
      <div class="fixed-bottom bg-light text-center small py-3 border-top">
        <div>Versão atual: {{version}}</div>
        <router-link to="/termos-uso" class="text-primary text-decoration-none">Termos de Uso</router-link>
        -
        <router-link to="/privacidade" class="text-primary text-decoration-none">Política de Privavidade</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "@/components/TopBar";
  export default {
    name: 'Perfil',
    components: {TopBar},
    computed: {
      regional() {
        return this.$store.state.dados.regional
      }
    }
  }
</script>
<style scoped>
</style>

<template>
  <div>
    <loading v-if="load" fullscrean="true" text="Aguarde, confirmando sua presença.."/>
    <div v-if="dados" v-show="!load">
      <form @submit.prevent="validarPresenca" v-if="!confirmado">
        <div class="mb-4">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="mr-2">
              <h6 class="font-weight-bold mb-0">{{reuniao.nome_reuniao}}</h6>
              <p class="mb-0">Data: {{reuniao.data_reuniao}}</p>
            </div>
            <a href="javascript:" class="btn btn-sm ml-auto" @click="$emit('voltar')" title="Saiba mais">
              <i class="fa fa-info-circle" style="font-size: 18px"></i>
            </a>
            <a href="javascript:" class="btn btn-sm text-secondary ml-2" @click="share()" title="Compartilhar link">
              <i class="fa fa-share" style="font-size: 20px"></i>
            </a>
          </div>
          <hr>
          <div class="alert alert-success d-flex align-items-center" v-if="confirmou">
            <i class="fa fa-check mr-1"></i>
            <div class="ml-1">
              <div>{{ dados.presente === '1' ? 'Presença confirmada' : 'Justificativa enviada' }} com sucesso!</div>
              <div class="text-dark small">Para cancelar, acesse a tela de <router-link to="/presencas" class="text-primary"><b>presenças</b></router-link></div>
            </div>
          </div>
          <div class="alert d-flex align-items-center" :class="reuniao.expirada == 1 ? 'alert-warning' : 'alert-info'" v-else>
            <i class="fa fa-info-circle mr-1"></i>
            <div class="ml-1">
              <h6 class="font-weight-bold mb-0" v-if="reuniao.expirada == 1 && dados.presente === '1'">Reunião Indisponível</h6>
              <div class="small" v-if="dados.presente === '1'">Confirme a presença até <b>4 horas</b> antes da reunião.</div>
              <div class="small" v-else>Informe a ausência até <b>30 minutos</b> antes da reunião.</div>
            </div>
          </div>
          <div>
            <div class="mb-2">
              <label class="mb-1 font-weight-bold small">Vai participar da reunião?</label>
              <div>
                <label class="d-flex align-items-center mb-1"><input type="radio" value="1" name="presente" v-model="dados.presente" :disabled="confirmou" class="mr-1" required>Sim</label>
                <label class="d-flex align-items-center mb-0"><input type="radio" value="0" name="presente" v-model="dados.presente" :disabled="confirmou" class="mr-1" required>Não</label>
              </div>
            </div>
            <div class="mb-2">
              <label class="font-weight-bold small mb-0">Nome completo *</label>
              <input type="text" class="form-control" placeholder="Informe seu nome" v-model="dados.nome_membro" maxlength="128" :disabled="confirmou" required>
            </div>
            <div v-if="dados.presente === '1'">
              <div class="mb-2" v-for="p in reuniao.perguntas">
                <label class="font-weight-bold small mb-0">{{ p.nome_pergunta }} {{ p.required == 1 ? '*' : '' }}</label>
                <select class="form-control" v-model="p.resposta" :required="p.required == 1" v-if="p.opcoes" :disabled="confirmou">
                  <option value="">{{ p.placeholder || 'Escolha uma opção'}}</option>
                  <option v-for="opt in p.opcoes" :value="opt">{{opt}}</option>
                </select>
                <input type="text" class="form-control" :placeholder="p.placeholder" v-model="p.resposta" maxlength="128" :required="p.required == 1" :disabled="confirmou" v-else>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="font-weight-bold small mb-0" for="observacao">{{ dados.presente === '1' ? 'Observações (opcional)' : 'Justificativa de ausência *' }}</label>
            <textarea id="observacao" class="form-control" :placeholder="dados.presente === '1' ? 'Ex: Acessos especiais, etc.' : 'Informe aqui o motivo da ausência.'"
                      v-model="dados.observacao" rows="4" maxlength="256" :required="dados.presente !== '1'" :disabled="confirmou" style="resize: none"></textarea>
          </div>
        </div>
        <div class="fixed-bottom bg-white py-3 shadow-sm border-top">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <button class="btn btn-secondary btn-block py-2" :class="{'disabled': reuniao.presente === '1' && reuniao.expirada == 1}" v-if="!confirmou">Enviar</button>
                <a href="javascript:" class="btn btn-outline-primary btn-block py-2" @click="novaConfirmacao()" v-else>Enviar nova confirmação</a>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div v-else>
        <div class="text-center mt-5" style="max-width: 600px">
          <h4 class="font-weight-bold text-primary mb-0">{{ dados.presente === '1' ? 'Confirmado' : 'Justificado' }} com sucesso!</h4>
          <p class="mb-3 small">
            Recebemos sua {{ dados.presente === '1' ? 'confirmação de presença' : 'justificativa de ausência' }}
          </p>
          <div class="card shadow-sm mb-4">
            <div class="card-body">
              <p class="mb-0">{{reuniao.nome_reuniao}}</p>
              <p class="mb-0"><b>Data:</b> {{reuniao.data_reuniao}}</p>
            </div>
          </div>
          <div class="pb-4">
            <router-link to="/presencas" class="btn btn-primary btn-block py-3 mb-2">Ver minhas presenças</router-link>
            <router-link to="/calendario" class="btn btn-outline-primary btn-block py-3">Voltar</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalBottom from "../components/ModalBottom";
  import Tooltip from "@/components/Tooltip";
  import Loading from "@/components/Loading";

  export default {
    name: 'CalendarioPresenca',
    components: {Loading, Tooltip, ModalBottom},
    props: ['reuniao'],
    data() {
      return {
        load: false,
        user: {},
        dados: null,
        confirmado: false,
        salvar: true,
        confirmacoes: [],
        confirmou: false
      }
    },

    methods: {
      validarPresenca() {
        if (this.load) {
          return;
        }

        if (this.dados.presente === '1' && this.reuniao.expirada == 1) {
          this.$swal('Presença Indisponível. Confirme sua presença até 4 horas antes da reunião.');
          return;
        }

        const presenca = this.confirmacoes.find(c => c.id_reuniao === this.reuniao.id_reuniao && c.nome_membro === this.dados.nome_membro);

        if (presenca) {
          this.confirmou = true;
          this.$swal(`Você já ${presenca.presente === '1' ? 'confirmou a presença' : 'justificou a ausência'} com este nome nesta reunião. Mude o "Nome Completo" caso queira enviar uma nova confirmação.`);
          return;
        }

        this.enviarConfirmacao();
      },

      enviarConfirmacao() {
        if (this.reuniao.perguntas) {
          const json = {};
          this.reuniao.perguntas.forEach(p => {
            json[p.slug] = p.resposta;
            this.dados.json_data = json
          });
        }

        this.dados.id_reuniao = this.reuniao.id_reuniao;
        this.load = true;

        this.$http.post('app/presencas/' + this.regionalId, this.dados)
          .then(response => {
            const data = response.data;

            if (data && data.success) {
              this.confirmado = true;

              // salvar a reuniao local
              this.dados.nome_reuniao = this.reuniao.nome_reuniao;
              this.dados.data_reuniao = this.reuniao.data_reuniao;
              this.dados.timestamp = this.convertTime(this.reuniao.data_reuniao);
              this.dados.id_presenca = data.id_presenca;
              this.confirmacoes.push(this.dados);
              localStorage.setItem('confirmacoes', JSON.stringify(this.confirmacoes));
              this.$store.state.notificacoes = this.confirmacoes;

              // salvar para usar depois
              if (this.salvar) {
                this.salvarUser();
              }

            } else {
              this.$swal(data && data.msg ? data.msg : 'Erro temporário');
            }

            this.load = false;

          }, res => {
            this.$swal(res.data && res.data.msg ? res.data.msg : 'Erro temporário');
            this.load = false;
          });
      },

      salvarUser() {
        this.user.nome_membro = this.dados.nome_membro || '';
        this.$store.commit('setDataUser', this.user);
      },

      convertTime(dataString) {
        const [data, hora] = dataString.split(' - ');
        const [dia, mes, ano] = data.split('/');
        const [horas, minutos] = hora.split(':');
        const date = new Date(ano, mes - 1, dia, horas, minutos);
        return date.getTime();
      },

      share() {
        try {
          navigator.share({
            text: `${this.reuniao.nome_reuniao} - ${this.reuniao.data_reuniao}.\n\nAcesse o link para confirmar sua presença.`,
            url: `https://calendarioccb.com.br/presenca/${this.reuniao.id_reuniao}?regional=${this.regionalId}`,
          });

        } catch (err) {
          this.$swal('Dispositivo incompatível com o compartilhamento de reuniões.');
        }
      },

      novaConfirmacao() {
        this.$swal({
          text: "Deseja enviar uma nova confirmação?",
          buttons: ["Cancelar", "Sim"],
          dangerMode: true
        }).then((res => {
          if (res) {
            this.confirmou = false;
            this.dados.nome_membro = '';
          }
        }));
      }
    },

    computed: {
      regionalId() {
        return this.$store.state.id_regional;
      },

      setores() {
        return this.$store.state.dados.setores
      }
    },

    mounted() {
      this.user = this.$store.state.user;
      this.confirmacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || [];

      this.dados = {
        id_reuniao: '',
        nome_membro: this.user.nome_membro || '',
        presente: '1',
        observacao: '',
        json_data: null
      }

      const presenca = this.confirmacoes.find(c => c.id_reuniao === this.reuniao.id_reuniao);

      if (presenca) {
        this.confirmou = true;
        this.dados.nome_membro = presenca.nome_membro || '';
        this.dados.presente = presenca.presente === '0' ? '0' : '1';
        this.dados.observacao = presenca.observacao || '';
      }
    }
  }
</script>

<style scoped>
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import helpers from './helpers'
import VueResource from 'vue-resource'
import VueSwal from 'vue-swal'
import VueFuse from 'vue-fuse'
import VCalendar from 'v-calendar';
import {version} from '../package'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/main.css'
import 'animate.css/animate.css'

Vue.use(VueSwal);
Vue.use(helpers);
Vue.use(VueResource);
Vue.use(VueFuse);
Vue.use(VCalendar);

Date.prototype.toDateInputValue = (function() {
  let local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0,10);
});

Vue.mixin({
  data() {
    return {
      version,
      oneSignalIntegration: (process.env.VUE_APP_ONESIGNAL && process.env.NODE_ENV !== 'development')
    }
  }
});

Vue.filter('phone', (phone) => {
  if (phone) {
    if (phone.length === 10) {
      return phone.replace(/[^0-9]/g, '')
        .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');

    } else if (phone.length === 11) {
      return phone.replace(/[^0-9]/g, '')
        .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
  }

  return phone
});

Vue.filter('tipo', (t) => {
  switch (t) {
    case '1':
      return 'Dúvidas';
    case '2':
      return 'Sugestões';
    case '3':
      return 'Reclamações';
    default:
      return 'Outros';
  }
});

Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;
Vue.http.options.root = process.env.VUE_APP_BASE_SERVER;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

<template>
  <i class="fa fa-info-circle fa-sm pointer" @click="tip"></i>
</template>

<script>
  export default {
    name: "Tooltip",
    props: ['msg'],
    methods: {
      tip() {
        this.$swal(this.msg);
      }
    }
  }
</script>

<template>
  <div>
    <botton-bar/>
    <div class="content">
      <div class="container">
        <loading v-if="load" fullscrean="true"/>
        <div v-else>
          <div v-if="!confirmacoes.length">
            <div class="alert alert-info d-flex align-items-center">
              <i class="fa fa-info-circle mr-2"></i>
              <div class="small">
                Acesse o <router-link to="/calendario:" class="font-weight-bold text-primary">calendário</router-link> para confirmar sua presença ou justificar sua ausência nas reuniões.
              </div>
            </div>
            <div class="text-center">
              Nenhuma presença ou justificativa de ausência registrada
            </div>
          </div>
          <div class="card shadow-sm mb-2" v-for="c in confirmacoes">
            <div class="card-body">
              <h5 class="text-primary mb-1">{{ c.nome_reuniao }}</h5>
              <div>
                <p class="small mb-0">Nome: {{c.nome_membro}}</p>
                <p class="small mb-0">Data da reunião: {{c.data_reuniao}}</p>
                <p class="small mb-0">Vai Participar? {{c.presente === '0' ? 'Não' : 'Sim'}}</p>
                <p class="small mb-0" v-if="c.observacao">{{c.presente === '0' ? 'Justificativa' : 'Observações'}}: {{c.observacao}}</p>
              </div>
              <hr>
              <div class="row no-gutters">
                <div class="col-6 pr-1">
                  <button class="btn btn-outline-dark btn-sm btn-block" @click="cancelarPresenca(c)">Cancelar</button>
                </div>
                <div class="col-6 pl-1">
                  <router-link :to="`/presenca/${c.id_reuniao}?regional=` + regionalId" class="btn btn-primary btn-sm btn-block">Visualizar</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from "@/components/Loading";
  import BottonBar from "@/components/BottonBar";

  export default {
    name: 'MinhasPresencas',
    components: {BottonBar, Loading},
    data() {
      return {
        load: false,
        confirmacoes: []
      }
    },

    methods: {
      getDados() {
        const confirmacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || [];
        this.$store.state.notificacoes = confirmacoes ? confirmacoes.sort((a, b) => a.timestamp - b.timestamp) : [];
        this.confirmacoes = confirmacoes;
      },

      cancelarPresenca(reuniao) {
        const id_presenca = reuniao.id_presenca;

        if (!id_presenca) {
          this.$swal("Não foi possível remover essa confirmação, contate sua regional.");
          return;
        }

        const tipo = reuniao.presente === '0' ? 'ausência' : 'presença';
        this.$swal({
          text: `Deseja cancelar o registro de ${tipo} desta reunião?`,
          buttons: ["Voltar", "Sim!"],
          dangerMode: true
        }).then(res => {
          if (res) {
            this.load = true;

            this.$http.post('app/presencas/' + this.regionalId, { id_presenca, id_reuniao: reuniao.id_reuniao, cancelar: true })
              .then(res => {
                this.load = false;

                if (res.data.success) {
                  this.confirmacoes = this.confirmacoes.filter(c => c.id_presenca !== id_presenca);
                  localStorage.setItem('confirmacoes', JSON.stringify(this.confirmacoes));
                  this.$swal(`Cancelado com sucesso!`);
                  this.getDados();
                }

              }, res => {
                this.load = false;
                let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
                this.$swal(msg);
              });
          }
        });
      }
    },

    mounted() {
      document.body.classList.remove("modal-open");
      this.getDados();
    },

    computed: {
      user() {
        return this.$store.state.user;
      },

      regionalId() {
        return this.$store.state.id_regional;
      }
    }
  }
</script>
<style scoped>
</style>

<template>
  <div>
    <top-bar :title="(step === 2) ? 'Presença' : 'Reunião'" :go-click="true" @voltar="voltar"/>
    <div>
      <loading :fullscrean="true" v-if="load"/>
      <div class="container" v-else style="max-width: 600px; padding-bottom: 92px">
        <div v-show="step === 1">
          <div class="text-center mb-4">
            <h4 class="text-primary font-weight-bold mb-0">{{reuniao.nome_reuniao}}</h4>
            <p class="mb-0">{{reuniao.descricao}}</p>
          </div>

          <div class="border-top py-3">
            <h6 class="font-weight-bold mb-1 text-primary">Localidade</h6>
            <p class="mb-0">{{ reuniao.nome_cidade }} - {{ reuniao.sigla_estado }} <span v-if="reuniao.nome_localidade">({{ reuniao.nome_localidade }})</span></p>
            <p class="mb-0" v-if="reuniao.local">Complexo: {{reuniao.local}}</p>
            <p class="mb-0" v-if="reuniao.endereco">{{reuniao.endereco}}, {{reuniao.numero}}, {{reuniao.bairro}}</p>
          </div>

          <div class="border-top py-3">
            <h6 class="font-weight-bold mb-1 text-primary">Data / Hora</h6>
            <p class="mb-0">{{ reuniao.data_reuniao }}</p>
            <p class="mb-0">Dia da Semana: {{ reuniao.dia_semana }}</p>
            <p class="mb-0" v-if="reuniao.hora_termino">Término (previsão): {{reuniao.hora_termino}}</p>
          </div>

          <div class="border-top py-3" v-if="reuniao.qtd_previsao">
            <h6 class="font-weight-bold mb-1 text-primary">Quantidade de irmãos</h6>
            <p class="mb-0">{{ reuniao.qtd_previsao }} irmãos (previsto)</p>
          </div>

          <div class="border-top py-3" v-if="reuniao.is_cozinha">
            <h6 class="font-weight-bold mb-1 text-primary">Haverá cozinha?</h6>
            <p class="mb-0">{{ reuniao.is_cozinha ? 'Sim' : 'Não' }}</p>
          </div>

          <div class="border-top py-3" v-if="reuniao.is_porteiro">
            <h6 class="font-weight-bold mb-1 text-primary">Haverá porteiros?</h6>
            <p class="mb-0">{{ reuniao.is_porteiro ? 'Sim' : 'Não' }}</p>
          </div>

          <div class="bg-white py-3 shadow-sm fixed-bottom border-top" v-if="reuniao.tipo_reuniao === 1">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <button class="btn btn-primary btn-block py-2" @click="confirmarPresenca()">Confirmar Presença</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 2">
          <calendario-presenca :reuniao="reuniao" @voltar="step=1"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Loading from "../components/Loading";
  import CalendarioPresenca from "@/views/CalendarioPresenca";

  export default {
    name: 'CalendarioReuniao',
    components: {CalendarioPresenca, Loading, TopBar},
    props: ['componente'],
    data() {
      return {
        step: 1,
        load: true,
        reuniao: {
          id_reuniao: '',
          nome_reuniao: 'Reunião'
        }
      }
    },

    methods: {
      getDados(id) {
        this.step = 1;
        this.load = true;
        this.$http.get('app/reuniao/' + this.regionalId + '/' + id)
          .then(response => {
            this.load = false;

            if (response.data && response.data.id_reuniao) {
              this.reuniao = response.data;

              if (this.$route.name === 'CalendarioPresenca') {
                this.confirmarPresenca()
              }
            }

          }, res => {
            console.log(res);
            this.load = false;
          });
      },

      confirmarPresenca() {
        this.step = 2;
      },

      voltar() {
        if (this.step === 1) {
          this.$router.push('/calendario');
        }

        this.step = 1;
      }
    },

    mounted() {
      const id_reuniao = this.$route.params.id;

      if (id_reuniao) {
        this.getDados(id_reuniao);
      }
    },

    created() {
      this.$parent.$on('loadDadosModal', (id_reuniao) => {
        this.getDados(id_reuniao);
      });
    },

    computed: {
      regionalId() {
        return this.$store.state.id_regional;
      }
    }
  }
</script>
<template>
  <div :style="!noPadding ? 'padding-bottom: 76px' : ''">
    <div class="fixed-top bg-white text-center shadow-sm">
      <div class="container position-relative">
        <div class="d-block position-absolute" style="left: 0">
          <a href="javascript:" @click="voltar" class="btn" style="margin: 8px 6px 0">
            <i class="fa fa-chevron-left text-primary"></i>
          </a>
        </div>
      </div>
      <h1 class="font-weight-bold h6 text-primary mx-auto limite" style="margin: 16px 0; max-width: 65%">
        {{title}}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  props: ['title', 'url', 'noPadding', 'goClick'],
  methods: {
    voltar(url) {
      if (this.goClick) {
        this.$emit('voltar');

      } else if (this.url) {
        this.$router.push(this.url);

      } else {
        this.$router.go(-1);
      }
    }
  }
}
</script>

<template>
  <div>
    <botton-bar/>
    <div class="content">
      <loading v-if="load" :fullscrean="true" text="Por favor aguarde"/>
      <div class="container" v-show="!load">
        <div class="row no-gutters justify-content-between align-items-end">
          <div class="col-md-6 text-center mx-auto">
            <h4 class="mb-0">Listas de Batismo</h4>
          </div>
          <div class="col-md-4 mt-3 mt-md-0">
            <select class="form-control" v-model="pesquisa.ano" @change="getListas()">
              <option :value="2025">2025</option>
              <option :value="2024">2024</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="mb-3" v-for="l in listas">
          <lista :nome="`${meses.find(m => m.valor == l.mes_lista).mes} de ${l.ano_lista}`" @go="abrirLista(l.id_lista)" icone="true">
            <div class="text-dark">
              Lista de Batismo e Diversos
            </div>
          </lista>
        </div>
      </div>
    </div>

    <lista-preview :meses="meses"/>
  </div>
</template>

<script>
  import BottonBar from "../components/BottonBar";
  import Lista from "../components/Lista";
  import Loading from "@/components/Loading";
  import ListaPreview from "@/components/ListaPreview";
  export default {
    name: 'Home',
    components: {ListaPreview, Loading, Lista, BottonBar},
    data() {
      return {
        anoAtual: new Date().getFullYear(),
        load: false,
        pesquisa: { ano: '' }
      }
    },

    methods: {
      getListas() {
        if (this.listas.length && this.listas[0].ano_lista === this.pesquisa.ano) {
          return;
        }

        this.load = true;
        this.dados = null;

        this.$http.get('app/listas/' + this.regionalId, { params: this.pesquisa })
          .then(response => {
            this.load = false;
            this.$store.state.listas = response.data || [];

          }, res => {
            this.load = false;
            console.log(res);
          });
      },

      abrirLista(id_lista) {
        this.$router.push('/listas/' + id_lista + '?regional=' + this.regionalId);
      }
    },

    mounted() {
      document.body.classList.remove("modal-open");
      this.pesquisa.ano = this.anoAtual;
      const id = this.$route.params.id;

      if (id && !isNaN(parseInt(id))) {
        this.$emit('openModal', id);

      } else {
        this.getListas();
      }
    },

    computed: {
      regionalId() {
        return this.$store.state.id_regional;
      },

      meses() {
        return this.$store.state.meses;
      },

      listas() {
        return this.$store.state.listas;
      }
    },

    watch: {
      $route(to, from) {
        if (to.name === 'Lista' && to.params.id) {
          this.$emit('openModal', to.params.id);

        } else {
          this.$emit('closeModal');
          this.getListas();
        }
      }
    }
  }
</script>
<style scoped>
</style>

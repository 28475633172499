<template>
  <div>
    <top-bar title="Meus Dados" />
    <div class="container" style="max-width: 600px">
      <form @submit.prevent="validate">
        <div>
          <div class="form-group mb-2">
            <label class="mb-0 font-weight-bold small">Nome</label>
            <input type="text" class="form-control" placeholder="Meu nome" v-model="dados.nome_membro" maxlength="100" required style="text-transform: capitalize">
          </div>
          <div class="form-group mb-2">
            <label class="mb-0 font-weight-bold small">Setor</label>
            <select class="form-control" v-model="dados.id_setor">
              <option value="">Selecione</option>
              <option :value="s.id_setor" v-for="s in setores">Setor - {{ s.nome_setor }}</option>
            </select>
          </div>
        </div>

        <div class="mt-3 mb-5 text-muted">
          <p class="small m-0">
            Ao continuar você concorda com os
            <router-link to="/termos-uso" class="text-primary text-decoration-none font-weight-bold">Termos de Uso</router-link>
            do app e confirma que leu a
            <router-link to="/privacidade" class="text-primary text-decoration-none font-weight-bold">Política de Privavidade</router-link>
          </p>
        </div>

        <div class="bg-white py-3 shadow-sm fixed-bottom border-top">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <button class="btn btn-secondary btn-block py-2">Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
  import {TheMask} from 'vue-the-mask';
  import TopBar from "../components/TopBar";

  export default {
    name: 'Perfil',
    components: {TopBar, TheMask},
    data() {
      return {
        token: localStorage.getItem('token'),
        dados:  {
          nome_membro: '',
          id_setor: '',
        },
      }
    },

    methods: {
      validate() {
        this.$store.commit('setDataUser', this.dados);
        this.$swal('Salvado com sucesso!');
      }
    },

    created() {
      document.body.classList.remove("modal-open");
    },

    mounted() {
      if (this.user.token) {
        this.dados = {
          token: this.user.token,
          nome_membro: this.user.nome_membro,
          id_setor: this.user.id_setor
        };
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      regional() {
        return this.$store.state.regional
      },

      setores() {
        return this.$store.state.dados.setores
      }
    },
  }
</script>

<style scoped>

</style>

<template>
  <div class="modal-reuniao" v-show="opened">
    <div class="modal-reuniao-content shadow-sm">
      <calendario-reuniao componente="true"/>
    </div>
  </div>
</template>

<script>
  import CalendarioReuniao from "@/views/CalendarioReuniao";
  import TopBar from "@/components/TopBar";
  export default {
    name: "Modal",
    components: {TopBar, CalendarioReuniao},
    props: ['title', 'overflow'],
    data() {
      return {
        opened: false
      }
    },

    methods: {
      closeModal() {
        document.body.classList.remove("modal-open");
        this.opened = false;
        this.$emit('closeModal');
      }
    },

    created() {
      this.$parent.$on('openModal', (dados) => {
        document.body.classList.add("modal-open");
        this.$emit('loadDadosModal', dados);
        this.opened = true;
      });

      this.$parent.$on('closeModal', () => {
        this.closeModal();
      });
    },
  }
</script>

<style scoped>
  .modal-reuniao {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1040; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .modal-reuniao-content {
    background-color: #fefefe;
    position: relative;
    overflow: auto;
    min-height: 100%;
    height: 100vh;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  @media (max-width: 600px) {
    .modal-reuniao-content {
    }
  }
</style>

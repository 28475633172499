<template>
  <div>
    <top-bar title="404"/>
    <div class="content">
      <div class="container text-center">
        <div style="margin-top: 100px">
          <h3 class="font-weight-bold mb-1">Página não encontrada</h3>
          <p>Essa página não existe ou foi removida.</p>
          <router-link to="/calendario" class="btn btn-outline-secondary">Voltar para Home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BottonBar from "../components/BottonBar";
  import TopBar from "@/components/TopBar";

  export default {
    name: 'P404',
    components: {TopBar, BottonBar}
  }
</script>
<template>
  <div>
    <botton-bar/>
    <div class="content">
      <div class="bg-light position-relative rounded-top">
        <div class="container">
          <router-link to="/perfil/dados" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
            Meus Dados
          </router-link>
          <button class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3" @click="logout">Apagar dados</button>
          <hr>
          <div class="card p-3 text-center">
            <div>
              <h6 class="font-weight-bold mb-0">{{ regional.nome_regional }} - {{ regional.sigla_estado }}</h6>
              <div class="small">RRM</div>
              <hr>
              <button class="btn font-weight-bold btn-outline-dark btn-block" @click="alterarRegional">Alterar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  import BottonBar from "@/components/BottonBar";
  export default {
    name: 'Perfil',
    components: {BottonBar, Icon, CardInfo, Lista, TopBar},
    data() {
      return {
        token: localStorage.getItem('token'),
      }
    },

    methods: {
      alterarRegional() {
        localStorage.removeItem('setor');
        this.$store.state.id_regional = null;
        this.$store.state.user.id_regional = null;
        this.$router.push('/');
      },

      logout() {
        this.$swal({
          text: "Deseja realmente apagar os dados do seu aplicativo?",
          buttons: ["Cancelar", "Sim!"],
          dangerMode: true
        }).then((res => {
          if (res) {
            this.$store.commit('logout');
            this.$router.push('/');
          }
        }));
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      regional() {
        return this.$store.state.dados.regional;
      }
    },

    mounted() {
      document.body.classList.remove("modal-open");
    }
  }
</script>

<template>
  <div>
    <top-bar title="Cadastro CRS" url="/"/>
    <div class="container" style="max-width: 600px">
      <div class="text-center" style="margin: 64px 0 22px">
        <img src="../assets/logo.png" alt="Congregação Cristã no Brasil" class="img-fluid" style="max-width: 128px;">
        <h2 class="font-weight-bold text-primary mt-3 mb-0">CRS</h2>
        <div>Calendário de Reuniões e Serviços</div>
      </div>
      <p class="mb-4">
        Por favor, clique no link abaixo para solicitar o cadastro ao CRS.
        Após o cadastro, a regional ficará em uma fila de espera e, assim que o acesso estiver disponível, ele será enviado por e-mail ou WhatsApp ao Secretário Responsável.
      </p>
      <div class="text-center border-top pt-4">
        <a href="https://forms.gle/TkA2GGioyEGXJW44A" target="_blank" class="btn btn-primary py-2 px-3">Cadastrar RRM</a>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "@/components/TopBar";
  export default {
    name: 'Perfil',
    components: {TopBar},
    computed: {
      regional() {
        return this.$store.state.dados.regional
      }
    }
  }
</script>

<style scoped>
</style>
